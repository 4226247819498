<template>
  <div class="login_container">
    <div class="login_box">
      <!--头像区域 -->
      <div class="avator-box">
        <img src="../assets/images/logo.png" />
      </div>
      <!-- 登录区域 -->
      <el-form
        label-width="0px"
        class="login_form"
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFromRef"
      >
        <!-- 用户名 -->
        <el-form-item prop="phone" size="medium">
          <el-input v-model.trim="loginForm.phone" prefix-icon="el-icon-s-custom" placeholder="请输入手机号/账号"/>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password" size="medium" class="psd-elitem">
          <el-input
              v-model.trim="loginForm.password"
              prefix-icon="el-icon-lock"
              type="password"
              @keyup.enter.native="login"
              placeholder="请输入密码"
          />
          <el-tooltip content="建议定期修改密码" placement="right" effect="light">
            <span class="tips-box">
                <i class="el-icon-warning-outline" style="cursor: pointer"></i>
            </span>
          </el-tooltip>
        </el-form-item>
        <el-form-item prop="verifyCode" class="vcode-item" size="medium" v-if="loginType===2">
          <el-input
              v-model.trim="loginForm.verifyCode"
              prefix-icon="el-icon-mobile"
              placeholder="请输入验证码"
          />
          <el-button type="primary" class="getcode-btn" :disabled="getCodeDisabled" @click="getCode">{{codeBtnWord}}</el-button>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading="loading">登录</el-button>
          <el-button type="info" @click="resetloginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        phone: '',
        password: '',
        verifyCode:''
      },

      loading: false,

      //表单验证规则对象
      loginFormRules: {
        //验证用户名
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' },

        ],
        //验证密码
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },
      isPsdWrong:false,
      wrongTimes:5,
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      countDownTime:60, // 获取验证码按钮失效时间
      getCodeDisabled: false,
      timer:null,
      loginType:2
    }
  },
  created() {
    let sendEndTime = localStorage.getItem('customerStartTimeLogin');
    if (sendEndTime) {
      this.getCodeDisabled=true
      this.countDown()
    }
    this.getLoginType()
  },
  methods: {
    //按钮重置事件
    resetloginForm() {
      this.loading = false
      this.$refs.loginFromRef.resetFields()
    },
    //登录事件
    login() {
      this.$refs.loginFromRef.validate(valid => {
        if (!valid) { return }
        this.loading = true
        this.$store.dispatch("loginAbout/login", this.loginForm).then(() => {
          this.loading = false
          // const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
          //取消自动登录呼叫中心
          // if (userInfo.isLogin===1){
          //   this.getBindPhone(userInfo.cno)
          // }
        }).catch(() => {
          this.loading = false
        })

        // if (userInfo.isLogin===1){
        //   this.getBindPhone(userInfo.cno)
        //   return;
        //   const params = {
        //     identifier: "heartmed", //企业编码 当showEnterprise为false时必填，否则忽略
        //
        //     cno: "2003", //座席工号 规则同identifier
        //
        //     password: "Heartmed@2022", // 密码 规则同identifier
        //
        //     bindTel: "20003", //绑定电话
        //
        //     bindType: 3, // 绑定类型，1：普通电话、2：IP话机、3：软电话
        //
        //     loginStatus: 1 //座席初始登录状态 当showAgentInitStatus为false时必填，否则忽略
        //   };
        //   this.$store.dispatch("calling/loginCalling",params)
        // }
      })
    },
    //获取坐席电话
    getBindPhone(cno){
      console.log(cno)
      const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
      this.$http.getCallingPhoneList({cno:cno}).then(res=>{
        console.log(res,'=============获取绑定电话============')
        //获取软电话
        let tel = res.result.data.filter(item=>item.telType===4)
        console.log(tel)
          const params = {
            identifier: userInfo.enterpriseCode, //企业编码 当showEnterprise为false时必填，否则忽略

            cno: userInfo.cno, //座席工号 规则同identifier

            password:userInfo.seatsPassword, // 密码 规则同identifier

            bindTel: tel[0].tel, //绑定电话

            bindType: 3, // 绑定类型，1：普通电话、2：IP话机、3：软电话

            loginStatus: 1 //座席初始登录状态 当showAgentInitStatus为false时必填，否则忽略
          };
          this.$store.dispatch("calling/loginCalling",params)
      })
    },
    countDown(){
      let startTime = localStorage.getItem('customerStartTimeLogin');
      let nowTime = new Date().getTime();
      if (startTime) {
        let surplus = 60 - parseInt((nowTime - startTime) / 1000, 10);
        this.countDownTime = surplus <= 0 ? 0 : surplus;
        this.codeBtnWord = `${this.countDownTime}s 后重新发送`
      } else {
        this.countDownTime = 60;
        this.codeBtnWord = `${this.countDownTime}s 后重新发送`
        localStorage.setItem('customerStartTimeLogin', nowTime);
      }
      this.timer = setInterval(() => {
        this.countDownTime--;
        this.codeBtnWord = `${this.countDownTime}s 后重新发送`
        this.getCodeDisabled = true;
        if (this.countDownTime <= 0) {
          localStorage.removeItem('customerStartTimeLogin');
          clearInterval(this.timer);
          this.countDownTime = 60;
          this.codeBtnWord='获取验证码'
          this.getCodeDisabled = false;

        }
      }, 1000)
    },
    getCode(){
      if (!this.loginForm.phone){
        this.$message.warning('请输入手机号/账号!')
      }else {
        this.countDown()
        this.$store.dispatch("loginAbout/getVerifyCode",{phone:this.loginForm.phone})
      }
    },
    //获取当前系统的登录方式
    getLoginType(){
      this.$http.getLoginType({dictCode:"login_type"}).then(res=>{
        this.loginType = res.result.data.dictValueId
        //用于控制展示人员管理解锁功能
      })
    }
  }
}
</script>

<style scoped lang="less">
.login_container {
  background: rgb(94,182,224);
  background: linear-gradient(150deg, rgba(94,182,224,1) 0%, rgba(0,118,181,1) 17%, rgba(6,137,167,1) 41%, rgba(6,176,182,1) 62%, rgba(5,152,179,1) 91%);
  background-size: 200% 200%;
  height: 100%;
  animation: gradient-move 15s ease alternate infinite;
}
@keyframes gradient-move {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
.login_box {
  width: 480px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow:
      2.8px 2.8px 2.2px rgba(0, 0, 0, 0.015),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.018),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.019),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.02),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.024),
      100px 100px 80px rgba(0, 0, 0, 0.05)
;
}

.avator-box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
}

.avator-box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: -6px;
  width: 99%;
  padding: 0 20px;
  box-sizing: border-box;
}
/deep/.psd-elitem{
  position: relative;
  .tips-box{
    margin-left: 4px;
    position: absolute;
  }
}
/deep/.vcode-item{
  .el-form-item__content{
    display: flex;
    .el-input{
      width: 80%;
      display: inline-block;
    }
    .getcode-btn{
      margin-left: 10px;
    }
  }
}

</style>
